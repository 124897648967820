$(".expand_button").on("click", function() {
    goToByScroll($(this).parent().parent().parent(), 10, 0.5);
    TweenMax.set(".expand_button img", { 
    	rotation: "0" 
    });
    TweenMax.set(".hidden_content_wrapper", { 
    	display: "none",
    	padding: "0px"
    });
    TweenMax.set($(this).find('img'), { 
    	rotation: "45" 
    });
    TweenMax.set($(this).parent().parent().parent().next(), {
    	display: "block",
    	padding: "10px 0"
    });
});

$(".expandable_row h3").on("click", function() {
    goToByScroll($(this).parent().parent(), 10, 0.5);
    TweenMax.set(".expand_button img", { 
        rotation: "0" 
    });
    TweenMax.set(".hidden_content_wrapper", { 
        display: "none",
        padding: "0px"
    });
    TweenMax.set($(this).parent().parent().find('img'), { 
        rotation: "45" 
    });
    TweenMax.set($(this).parent().parent().next(), {
        display: "block",
        padding: "10px 0"
    });
});