/* load images from divs depending on screen size */
function getAttributes ( $node ) { // function for gathering a node elements attributes
    var attrs = {};
    $.each( $node[0].attributes, function ( index, attribute ) {
        attrs[attribute.name] = attribute.value;
    } );

    return attrs;
}
$(document).ready(function() {
    var do_images = function() {
        var setURL;
        $.each($(".SURGE_iamge_swap"), function() {
            current_URL = $(this).attr('current');
            if (Modernizr.mq('(max-width: 991px)')) {
                setURL = $(this).attr('data-mobile');
            } else {
                setURL = $(this).attr('data-desktop');
            }
            if (typeof current_URL === typeof undefined) {
                // must be fist go - is div - so convert it to an image                
                var this_el_atts = getAttributes($(this)); // gather all the attributes that are on the div
                this_el_atts.current = setURL; // set the src to the correct image url for the screen size
                this_el_atts.style = "background-image: url("+setURL+");"; // set the correct image url for the screen size            
                $(this).replaceWith(function() { // replace the div with another node element
                    return $("<div>", this_el_atts); // return an image that has all the attributes that we collected
                });
            } else {
                if (current_URL !== setURL) {
                    $(this).attr("style", "background-image: url("+setURL+");");
                    $(this).attr('current', setURL);
                }
            }
        });
    };
    $(window).on("resize", do_images);
    do_images();

});