var set_col_hights = function (el, extra) {
  if (typeof extra === "undefined") {
    extra = 0;
  }

  if ($(el).css("min-height")) {
    TweenMax.set($(el), {
      clearProps: "min-height"
    });
  }

  // Get an array of all element heights
  var elementHeights = $(el).map(function () {
    return $(this).height();
  }).get();
  //console.log(elementHeights);
  // Math.max takes a variable number of arguments
  // `apply` is equivalent to passing each height as an argument
  var maxHeight = Math.max.apply(null, elementHeights);

  // Set each height to the max height
  $(el).css("min-height", maxHeight + extra + "px");
};

// how to use...
//set_col_hights('.new_arrival_product h3');

var do_these_heights = function () {
  if (Modernizr.mq('(min-width: 767px)')) {
    // Fix for empty elements getting a height set on them
    $("p, h1, h2, h3, h4").each(function () {
      if ($(this).is(':empty')) {
        $(this).css("display", "none");
      }
    });
  }
};

function getBiggestHeight(height_to_compare) {

  var this_outerheight;
  var array_of_heights = [];

  $(height_to_compare).each(function () {
    this_height = $(this).height();
    //console.log(this_outerheight);
    array_of_heights.push(this_height);
  });

  var largest_height = Math.max.apply(null, array_of_heights);
  //console.log(largest_height);

  TweenMax.set($(height_to_compare), {
    minHeight: largest_height
  });
}

// Get outerheight if you are using also padding.
function getBiggestOuterHeight(height_to_compare) {

  var this_outerheight;
  var array_of_heights = [];

  $(height_to_compare).each(function () {
    this_outerheight = $(this).outerHeight(true);
    //console.log(this_outerheight);
    array_of_heights.push(this_outerheight);
  });

  var largest_height = Math.max.apply(null, array_of_heights);
  //console.log(largest_height);

  TweenMax.set($(height_to_compare), {
    minHeight: largest_height
  });
}

$(window).load(function () {
  do_these_heights();
  if ($(window).width() > 991) {
    getBiggestOuterHeight('.new_arrival_product .header_wrap');
  }
});

$(window).resize(function () {
  do_these_heights();
  if ($(window).width() > 991) {
    getBiggestOuterHeight('.new_arrival_product .header_wrap');
  }
});

// Do heights for products on desktop
var do_desktop_product_cat_heights = function () {
  console.log("in");
  var desktop_archive_products = $("ul.products > li");
  for (var i = 0; i < desktop_archive_products.length; i += 3) {
    desktop_archive_products.slice(i, i + 3).wrapAll("<div class='product_row clearfix'></div>");
  }

  $(".product_row").each(function () {
    var that = this;
    // Wrap the images first
    $(that).find("img").wrap("<div class='image_inner_wrap'></div>");
    $(that).find(".image_inner_wrap").wrap("<div class='image_wrap'></div>");
    setTimeout(function () {
      getBiggestHeight($(that).find("li h2"));
      getBiggestOuterHeight($(that).find("span.price"));
      getBiggestOuterHeight($(that).find("li"));
      $(that).find("li").css({
        "margin-bottom": "-1px",
        "margin-right": "-1px"
      })
    }, 200);
  })
}
