$(window).load(function () {
  if ($(window).width() > 991) {
    $(".left_text_block").css("min-height", $(".right_image_block").height());
    $(".left_content").css("min-height", $(".latest_products_slider").height());
  }
  if ($(window).width() < 992) {
    $(".right_image_block").each(function () {
      $(this).insertBefore($(this).prev());
    });
  }
});

$(window).resize(function () {
  if ($(window).width() > 991) {
    $(".left_text_block").css("min-height", $(".right_image_block").height());
    $(".left_content").css("min-height", $(".latest_products_slider").height());
  }
  if ($(window).width() < 992) {
    $(".right_image_block").each(function () {
      $(this).insertBefore($(this).prev());
    });
  }
});

if ($(window).width() > 991) {
  $('.category_link').hover(function () {
    TweenMax.to($(".category_link"), 0.5, {
      width: "30%"
    });
    TweenMax.to($(this), 0.5, {
      width: "40%"
    });
  }, function () {
    TweenMax.to($(".category_link"), 0.5, {
      width: "33.3%"
    });
  });
}


// Blog
$(".year_expand_button").on("click", function () {
  $(".month_group_wrap").css("display", "none");
  TweenMax.to($(".year_expand_button"), 0.4, {
    rotation: 0
  });
  $(this).parent().find($(".month_group_wrap")).css("display", "block");
  TweenMax.to($(this), 0.4, {
    rotation: 180
  });
});

$(".month_expand_button").on("click", function () {
  $(".row_post").css("display", "none");
  TweenMax.to($(".month_expand_button"), 0.4, {
    rotation: 0
  });
  $(this).parent().find($(".row_post")).css("display", "block");
  TweenMax.to($(this), 0.4, {
    rotation: 180
  });
});

$(window).load(function () {
  if ($(window).width() > 767) {
    do_desktop_product_cat_heights();
  }
});

$(window).resize(function () {
  if ($(window).width() > 767) {
    do_desktop_product_cat_heights();
  }
});

$(".open_mobile_nav").on("click", function () {
  if ($(window).width() < 992) {
    if ($("#wrapper_for_SURGE_mega_menu_1").css("display") == "block") {
      $("#wrapper_for_SURGE_mega_menu_1").css("display", "none");
    } else {
      $("#wrapper_for_SURGE_mega_menu_1").css("display", "block");
    }
  }
});

$(".search_btn").on("click", function () {
  if ($(".mini_menu_search").css("display") == "block") {
    $(".mini_menu_search").fadeOut(200);
  } else {
    $(".mini_menu_search").fadeIn(200);
  }
});
